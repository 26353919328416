import Config from '../config/general-config';
import baseService from './baseService';

const authService = (user, password) =>
  baseService.postRequest(Config.ENDPOINTS.LOGIN, {
    _usuario: user,
    _contrasenia: password,
  });

export default authService;
