import React from 'react';
import TitleModule from '../core/TitleModule';
import { Container, Row, Col } from 'react-bootstrap';
import UploadPhotoDigitalFile from '../UploadPhotoDigitalFile';
import './../../styles/components/DigitalFile.scss';
import MandatoryDocuments from '../MandatoryDocuments';
import LockModifications from './../generic/LockModifications';
import SizeWeight from './../SizeWeight';

export default function DigitalFile() {
  return (
    <React.Fragment>
      <TitleModule title={'Expediente digital'} />
      <Container className="digital-file px-4 py-md-4">
        <Row className="mb-4">
          <Col xs={12}>
            <div className="file-description verdana-font text-center text-md-start">
              Lorem ipsum dolor, sit amet consectetur adipisicing elit.
              Perferendis dolorum quasi laboriosam delectus facere in fugit
              molestiae quis culpa pariatur ab quos adipisci recusandae
              quibusdam sit, dolore dolor vero deserunt.
            </div>
          </Col>
        </Row>
        <Row className="documents-container">
          <Col xs={12}>
            <LockModifications />
          </Col>
          <Col xs={12} md={4} className="mb-4">
            <UploadPhotoDigitalFile />
          </Col>
          <Col xs={12} md={8}>
            <MandatoryDocuments />
          </Col>
          <hr className="my-4"></hr>
          <Col xs={12}>
            <SizeWeight />
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
}
