import { showToast, TYPE_ICON } from './sweetAlert.helper';
import { showMessage } from './sweetAlert.helper';
import Config from './../config/general-config';
import notificacionesService from './../services/notificacionesService';
import { getActiveUser } from './localstorage.helper';

const showNotification = (message, options = {}) => {
  const notification = new Notification(message, options);
};

const enableNotifications = async (idUser) => {
  try {
    await enablePushNotifications(idUser);
    if (!('Notification' in window)) {
      showToast(
        'Funcionalidad no soportada',
        'Este navegador no soporta notificaciones',
        TYPE_ICON.INFO
      );
      return;
    }

    if (Notification.permission === 'granted') {
      return;
    }
    if (Notification.permission === 'denied') {
      return;
    }
    await showMessage(
      'Activar notificaciones',
      'Si desea recibir notificaciones push en su dispositivo, por favor actívelas cuando aparezca el diálogo correspondiente en su navegador'
    );

    Notification.requestPermission().then(async function (permission) {
      if (permission === 'granted') {
        await enablePushNotifications(idUser);
        showNotification('¡Las notificaciones se han activado correctamente!');
      }
    });
  } catch (e) {
    console.log('Enabling notifications', e);
  }
};

const enablePushNotifications = async (idUser) => {
  const swr = await navigator.serviceWorker.ready;
  let subs = await swr.pushManager.getSubscription();
  try {
    console.log('Enable notifications');

    // if (idUser === getActiveUser()) {
    //   return;
    // }

    // console.log('Enable notifications 2');
    if (subs) {
      await subs.unsubscribe();
    }
    subs = await swr.pushManager.subscribe({
      userVisibleOnly: true,
      applicationServerKey: Config.PUBLIC_VAPID_KEY,
    });
    const response = await notificacionesService.subscribe(idUser, subs);
    console.log('Response push: ', response);
    if (response) {
      showNotification('Se han activado las notificaciones push');
    }
  } catch (e) {
    await subs.unsubscribe();
    showToast(
      'Error',
      'No se ha podido suscribir a las notificaciones push',
      TYPE_ICON.WARNING
    );
  }
};

const listenBroadcastNotification = () => {
  const broadcast = new BroadcastChannel('sac_notifications');
  broadcast.onmessage = (event) => {
    if (event.data && event.data.type === 'SAC_NEW_NOTIFICATION') {
      const { payload } = event.data;
      console.log('Main thread listening notifications', payload);
    }
  };
};

export { showNotification, enableNotifications, listenBroadcastNotification };
