import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import utc from 'dayjs/plugin/utc';
import { isTruthy } from './utils';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import 'dayjs/locale/es-mx';
import customParseFormat from 'dayjs/plugin/customParseFormat';

dayjs.extend(localizedFormat);
dayjs.extend(isBetween);
dayjs.extend(utc);
dayjs.locale('es-mx');
dayjs.extend(customParseFormat);

export const dateBetween = (inicio, termino, fecha = null) => {
  if (!fecha) {
    return dayjs().isBetween(inicio, termino, 'day', '[]');
  }
  if (!termino) {
    return dayjs(fecha).isAfter(inicio);
  }
  return dayjs(fecha).isBetween(inicio, termino, 'day', '[]');
};

export const formatearFecha = (fecha, separator = '/') => {
  if (!isTruthy(fecha)) {
    return 'No definida';
  }
  return dayjs(fecha).format(`DD${separator}MM${separator}YYYY`);
};

export const getLocalDate = () => {
  let dateUTC = dayjs.utc();
  return dateUTC.local().format();
};

export const getCurrentYear = () => {
  return new Date().getFullYear();
};

export const spanishFormattedDate = (date) => {
  const _format = 'dddd DD [de] MMMM [de] YYYY';
  if (date) {
    return dayjs(date).format(_format);
  }
  return dayjs().format(_format);
};

export const compareDates = (date1, date2, type = 'ASC') => {
  const d1 = dayjs(date1, 'DD/MM/YYYY');
  const d2 = dayjs(date2, 'DD/MM/YYYY');
  if (d1.isSame(d2)) return 0;
  if (type === 'ASC') {
    if (d1.isBefore(d2)) return -1;
    if (d1.isAfter(d2)) return 1;
  } else {
    if (d1.isBefore(d2)) return 1;
    if (d1.isAfter(d2)) return -1;
  }
};

export const isBeforeXDays = (date, days) => {
  return dayjs(date, 'DD/MM/YYYY').isBefore(
    dayjs().local().subtract(days, 'days')
  );
};
