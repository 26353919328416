import React, { useState, useEffect } from 'react';
import '../../styles/components/FileViewer.scss';
import { downloadFile, getExtentionFromMimeType } from './../../utils/utils';

export default function FileViewer({ file }) {
  const [previewFile, setPreviewFile] = useState(null);

  useEffect(() => {
    if (!file) {
      return;
    }
    const ext = getExtentionFromMimeType(file.mimeType);
    if (!['.pdf', '.png', '.gif', '.jpg', '.jpeg'].includes(ext)) {
      downloadFile(file);
    } else {
      setPreviewFile(file);
    }
  }, [file]);

  const style = {
    height: '100vh',
    width: '100%',
    margin: '0 auto',
  };

  return (
    previewFile && (
      <div className="pdf-viewer">
        <div className="pdf-viewer-container">
          <div
            className="go-back p-2"
            onClick={() => {
              setPreviewFile(null);
            }}
          >
            <i className="fa-solid fa-times text-white fa-2x cursor-pointer"></i>
          </div>
          <embed
            src={`data:${previewFile.mimeType};base64,${previewFile.fileData}`}
            style={previewFile.mimeType === 'application/pdf' ? style : null}
            type={previewFile.mimeType}
          ></embed>
        </div>
      </div>
    )
  );
}
