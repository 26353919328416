import React, { useState, useEffect } from 'react';
import './../styles/components/BoardMessage.scss';
import { Link } from 'react-router-dom';
import useNotifications from './../hooks/useNotifications';
import Config from './../config/general-config';
import { ReactComponent as RemainderIcon } from './../assets/imgs/bell.svg';
import { ReactComponent as NoticeIcon } from './../assets/imgs/notification_icon.svg';

export default function BoardMessage() {
  const notifications = useNotifications([]);
  const [lastNotification, setLastNotification] = useState(null);
  const [redirectTo, setRedirectTo] = useState('/avisos-notificaciones');

  useEffect(() => {
    const _lastNotification = notifications[0];
    if (_lastNotification?.tipoNotificacion === Config.TYPE_NOTIFICATION.TASK) {
      setRedirectTo('/tareas');
    } else {
      setRedirectTo('/avisos-notificaciones');
    }
    setLastNotification(_lastNotification);
  }, [notifications]);

  return (
    lastNotification && (
      <div className="board-message">
        <div className="board-message-container d-flex flex-row align-items-center">
          <div
            className={`board-message-icon-container ${
              lastNotification.tipoNotificacion ===
              Config.TYPE_NOTIFICATION.NOTICE
                ? 'bg-orange'
                : 'bg-teal'
            }`}
          >
            {lastNotification.tipoNotificacion ===
            Config.TYPE_NOTIFICATION.NOTICE ? (
              <NoticeIcon />
            ) : (
              <RemainderIcon />
            )}
          </div>
          <div className="board-message-detail-container">
            <div className="board-message-title text-primary font-bold monserrat-bold-font f-16px">
              {lastNotification.notTitulo}
            </div>
            <div className="board-message-detail f-12px">
              {lastNotification.notDescripcion}
            </div>
          </div>
          <div className="board-message-action-container d-none d-md-flex align-items-center">
            <Link
              className="btn board-message-action bg-orange text-white f-12px"
              to={redirectTo}
            >
              Leer
            </Link>
          </div>
        </div>
      </div>
    )
  );
}
