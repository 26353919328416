import React from 'react';
import { Navigate } from 'react-router-dom';
import RouterPrivado from './../../routers/RouterPrivado';
import { useSelector } from 'react-redux';
import Header from './Header';
import SideMenu from './SideMenu';
import useWindowDimensions from '../../hooks/useWindowDimensions';

export default function RouterOutletPrivado() {
  const logged = useSelector((state) => state.user.logged);
  const { width } = useWindowDimensions();
  return logged ? (
    <React.Fragment>
      <Header />
      <div className="content d-flex">
        {width > 76 && <SideMenu />}
        <div className="outlet w-100">
          <RouterPrivado></RouterPrivado>
        </div>
      </div>
    </React.Fragment>
  ) : (
    <Navigate to="/login" replace="true"></Navigate>
  );
}
