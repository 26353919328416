import baseService from './baseService';
import Config from './../config/general-config';
import { getToken } from '../helpers/localstorage.helper';
import wsResponseHandler from './../handlers/wsResponse.hanlder';

const notificacionesService = {
  getNotificaciones: async (idUsuario) => {
    const response = await baseService.getRequest(
      `${Config.ENDPOINTS.NOTIFICATIONS}?_idUsuario=${idUsuario}`,
      null,
      {
        Authorization: `Bearer ${getToken()}`,
      }
    );
    return wsResponseHandler(response);
  },
  readNotifications: async (idUsuario, notifications) => {
    const response = await baseService.postRequest(
      `${Config.ENDPOINTS.READ_NOTIFICATION}?_idUsuario=${idUsuario}`,
      notifications,
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }
    );
    return response.estatus;
  },
  subscribe: async (idUser, subscription) => {
    const response = await baseService.postRequest(
      `${Config.ENDPOINTS.SUBSCRIBE_NOTIFICATION}`,
      {
        idUsuario: idUser,
        suscripcionJson: JSON.stringify(subscription),
      },
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }
    );
    return response.estatus;
  },
};

export default notificacionesService;
