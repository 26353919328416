import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

export default function PrivacyNotice({ showModal, onClose }) {
  return (
    <div className="privacy-notice">
      <Modal show={showModal} onHide={onClose} centered>
        <Modal.Header closeButton className="bg-orange text-white">
          <Modal.Title>Aviso de privacidad</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          React-Bootstrap replaces the Bootstrap JavaScript. Each component has
          been built from scratch as a true React component, without unneeded
          dependencies like jQuery. As one of the oldest React libraries,
          React-Bootstrap has evolved and grown alongside React, making it an
          excellent choice as your UI foundation.
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <Button variant="primary" onClick={onClose}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
