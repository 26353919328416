import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { showLoader, hideLoader } from '../stores/slices/loaderSlice';
import { showToast, TYPE_ICON } from '../helpers/sweetAlert.helper';
import NotificationController from './../controllers/notification.controller';
import notificacionesService from './../services/notificacionesService';
import { orderArrayByDate } from '../utils/utils';

const useNotifications = (initialValue) => {
  const { isOnline } = useSelector((state) => state.internet);
  const [notifications, setNotifications] = useState(initialValue ?? []);
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();

  useEffect(() => {
    const notificationController = new NotificationController(
      user.email,
      user.keyDecrypt
    );
    const fetchNotifications = async () => {
      try {
        let _notifications = await notificacionesService.getNotificaciones(
          user.id
        );
        _notifications = _notifications.map((item) => ({
          idUsuario: user.id,
          ...item,
          read: false,
          fechaLectura: null,
        }));
        notificationController.addNotifications(_notifications, user.id);
        orderArrayByDate(_notifications, 'fecha', 'DESC');
        setNotifications(_notifications);
      } catch (e) {
      } finally {
        dispatch(hideLoader());
      }
    };

    if (!user.id) return;
    dispatch(showLoader());

    if (isOnline) {
      fetchNotifications();
    } else {
      notificationController
        .readNotifications(user.id)
        .then((_notifications) => {
          if (_notifications.length === 0 && !isOnline) {
            showToast(
              'Conexión necesaria',
              'Para llevar a cabo la primera descarga de datos es necesaria una conexión a internet. Intente nuevamente una vez que su dispositivo tenga conexión.',
              TYPE_ICON.WARNING
            );
          } else if (_notifications.length === 0 && isOnline) {
            fetchNotifications();
          } else {
            orderArrayByDate(_notifications, 'fecha', 'DESC');
            setNotifications(_notifications);
          }
        })
        .catch((e) =>
          showToast(
            'Error',
            'No se ha podido leer la información de las notificaciones',
            TYPE_ICON.ERROR
          )
        )
        .finally(() => {
          dispatch(hideLoader());
        });
    }
  }, [dispatch, user.id, user.email, user.keyDecrypt, isOnline]);

  return notifications;
};

export default useNotifications;
