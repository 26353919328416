import React from 'react';
import checkIcon from './../assets/imgs/check_icon.svg';
import './../styles/components/FormativeField.scss';

export default function FormativeField({ field }) {
  const { matNombreCorto, matDescripcion } = field;
  return (
    <div className="formative-field pt-2 pb-3">
      <div className="formative-field-title-container grid-title text-center">
        <div className="formative-field-title mb-3">
          <span className="monserrat-bold-font text-primary">
            {matNombreCorto}
          </span>
        </div>
      </div>
      <div className="formative-field-image-container grid-icon">
        <img
          src={checkIcon}
          alt="Campo formativo"
          className="formative-field-icon"
        />
      </div>
      <div className="formative-field-description-container grid-description">
        <div className="formative-field-description">{matDescripcion}</div>
      </div>
    </div>
  );
}
