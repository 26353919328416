import React from 'react';
import notificationsIcon from '../assets/imgs/notification.svg';
import './../styles/components/Notifications.scss';
import { Link } from 'react-router-dom';

export default function Notifications() {
  return (
    <Link className="notifications" to={'/avisos-notificaciones'}>
      <div className="notifications-icon-container header-icon-container">
        <img
          src={notificationsIcon}
          alt="Notificaciones"
          className="notifications-icon header-icon"
        />
      </div>
    </Link>
  );
}
