import React from 'react';
import './../styles/components/StoreProduct.scss';
import { useDispatch } from 'react-redux';
import { addProduct } from './../stores/slices/carSlice';

export default function StoreProduct({ product }) {
  const dispatch = useDispatch();

  const {
    articulo,
    artDescripcion: descripcion,
    artPrecioUnitario: precio,
    fotoBase64: img,
  } = product;

  return (
    <div className="store-product">
      <div className="product-container">
        <div className="product-img-container">
          <img src={img} alt={descripcion} />
        </div>
        <div className="product-details">
          <div className="produtc-detail-price d-flex justify-content-between f-12px my-2">
            <span className="product-name font-bold">{articulo}</span>
            <div className="product-price">$ {precio}</div>
          </div>
          <div className="product-description f-14px">{descripcion}</div>
        </div>
        <div className="product-options  d-flex justify-content-between">
          <button
            type="button"
            className="btn btn-sm bg-orange monserrat-bold-font text-white"
            onClick={() => dispatch(addProduct(product))}
          >
            <i className="fas fa-shopping-cart">&nbsp;</i>
            Agregar
          </button>
          <button
            type="button"
            className="btn btn-sm monserrat-bold-font text-orange border-orange border-2"
          >
            Comprar
          </button>
        </div>
      </div>
    </div>
  );
}
