// const API_URL_BASE = 'http://localhost/API_SAC2/api';
const API_URL_BASE = 'https://servicios.sac.redgto.com.mx';
const Config = {
  production: false,
  version: '1.0 alpha',
  captcha_characters: 6,
  // captcha_key: '6Lf5wowgAAAAAOxuHPGMQuaD5_OLITx-OUlKDSft',
  // captcha_secret: '6Lf5wowgAAAAAOFKqP3QEugJX72fWnoIRRkGCU1G',
  captcha_key: '6Lfnm6shAAAAAGP0hRYQrfj_n_9ms6K9JnvQSAu1',
  captcha_secret: '6Lfnm6shAAAAABosrzjgGM2rYygL4i-r0_j6B2lB',
  PUBLIC_VAPID_KEY:
    'BPZunewkOg4S80TVjr2-J-Evilclo3kiOIIh0PkNVUVEmOqeCe3uz3xmUagWcN7RABTvLEydb7_-ibvWP2WMjeo',
  ENDPOINTS: {
    DIGITAL_FILE: `${API_URL_BASE}/ExpedienteDigital`,
    DOCS_MANAGMENT: `${API_URL_BASE}/escuela/gestionDocumentos`,
    EDUCATIONAL_PATH: `${API_URL_BASE}/trayectoriaAlumno`,
    GENERATE_ORDER: `${API_URL_BASE}/crearPedido`,
    LOGIN: `${API_URL_BASE}/Login`,
    LIST_ORDERS: `${API_URL_BASE}/consultaPedidosPadre`,
    LIST_PRODUCTS: `${API_URL_BASE}/consultaArticulos`,
    MENU_BOARD: './data/menu.json',
    NOTIFICATIONS: `${API_URL_BASE}/Notificaciones`,
    READ_NOTIFICATION: `${API_URL_BASE}/leerNotificacion`,
    SCHOOLARSHIPS_DOCS: `${API_URL_BASE}/becas`,
    SUBSCRIBE_NOTIFICATION: `${API_URL_BASE}/suscribirNotificaciones`,
    UPDATE_SIZE_WEIGHT: `${API_URL_BASE}/alumno/actualizaMedidas`,
    UPLOAD_DOCUMENT: `${API_URL_BASE}/ExpedienteDigital/cargarDocumento`,
    VIEW_DOCUMENT: `${API_URL_BASE}/archivoExpedienteDigital`,
    VIEW_DOCUMENT_SCHOOLARSHIPS: `${API_URL_BASE}/documentoBeca`,
  },
  LOCALSTORAGE_KEYS: {
    JWT: 'sac_jwt',
    ACTIVE_USER: 'sac_au',
  },
  validImagesExtentions: '.png,.jpg,.jpeg',
  validDocumentsExtentions: '.pdf, .doc, .docx, .png,.jpg,.jpeg',
  MAX_IMG_SIZE_MB: 2,
  MAX_DOC_SIZE_MB: 1,
  TYPE_NOTIFICATION: {
    NOTICE: 'Aviso',
    REMAINDER: 'Recordatorio',
    TASK: 'Tarea grupal',
  },
  DELETE_NOTIFICATION_AFTER_DAYS: 30,
  SYNC_ACTIONS: {
    MARK_AS_READ: 'mark_as_read',
    UPLOAD_DOC: 'upload_doc',
    UPLOAD_PHOTO: 'upload_photo',
    UPDATE_SIZE_WEIGHT: 'update_size_weight',
  },
  FILE_STATUS: {
    NO_LOADED: 0,
    LOADED: 1,
    VALIDATED: 2,
    REJECTED: 3,
  },
  MIN_SIZE: 0.5,
  MAX_SIZE: 1,
  MIN_WEIGHT: 10,
  MAX_WEIGHT: 30,
};

export default Config;
