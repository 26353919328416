import baseService from './baseService';
import Config from './../config/general-config';
import wsResponseHandler from './../handlers/wsResponse.hanlder';
import { getToken } from '../helpers/localstorage.helper';

const institutionService = {
  getConfig: async (idSchool) => {
    const response = await baseService.getRequest(
      Config.ENDPOINTS.DOCS_MANAGMENT,
      {
        _idEscuela: idSchool,
      },
      {
        Authorization: `Bearer ${getToken()}`,
      }
    );
    return wsResponseHandler(response);
  },
};

export default institutionService;
