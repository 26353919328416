import { combineReducers } from 'redux';
import userReducer from '../stores/slices/userSlice';
import internetReducer from '../stores/slices/internetSlice';
import menuReducer from '../stores/slices/menuSlice';
import institutionReducer from '../stores/slices/institutionSlice';
import studentReducer from '../stores/slices/studentSlice';
import loaderSliceReducer from './../stores/slices/loaderSlice';
import cartReducer from './../stores/slices/carSlice';

const reducers = combineReducers({
  user: userReducer,
  internet: internetReducer,
  menu: menuReducer,
  institution: institutionReducer,
  student: studentReducer,
  loader: loaderSliceReducer,
  cart: cartReducer,
});

export default reducers;
