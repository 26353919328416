import React from 'react';
import RouterApp from './../../routers/RouterApp';
import Footer from './Footer';
import Loader from './Loader';

export default function Layout() {
  return (
    <div id="page-container">
      <div id="content-wrap">
        <Loader />
        <RouterApp />
      </div>
      <Footer />
    </div>
  );
}
