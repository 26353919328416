import Institution from '../models/institution.model';

class InstitutionController {
  #model;

  constructor(email, secretKey) {
    this.#model = new Institution(email, secretKey);
  }

  addInstitution(institution) {
    return this.#model.addInstitution({ ...institution, config: null });
  }

  putInstitution(institution) {
    return this.#model.putInstitution({ ...institution, config: null });
  }

  readInstitution(id) {
    return this.#model.readInstitution(id);
  }

  putSchoolarships(id, schoolarships) {
    schoolarships = schoolarships.map((item) => ({
      idInstitucion: id,
      ...item,
    }));
    return this.#model.putSchoolarships(schoolarships);
  }

  readSchoolarships(id) {
    return this.#model.readSchoolarships(id);
  }

  updateSchoolarShipFile(data) {
    return this.#model.updateSchoolarShipFile(data);
  }

  readSchoolarshipFile(id, idDocument) {
    return this.#model.readSchoolarshipFile(id, idDocument);
  }

  readConfig(id) {
    return this.#model.readConfig(id);
  }

  updateConfig(id, config) {
    return this.#model.updateConfig(id, config);
  }
}

export default InstitutionController;
