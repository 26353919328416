import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import Board from './../components/pages/Board';
import NoticesRemainders from './../components/pages/NoticesRemainders';
import Tasks from './../components/pages/Tasks';
import EducationalPath from './../components/pages/EducationalPath';
import DigitalFile from './../components/pages/DigitalFile';
import Schoolarships from './../components/pages/Schoolarships';
import Store from './../components/pages/Store';
import OrderDetail from '../components/pages/OrderDetail';
import Orders from '../components/pages/Orders';

export default function RouterPrivado() {
  return (
    <Routes>
      <Route path="/" element={<Board />}></Route>
      <Route
        path="/avisos-notificaciones"
        element={<NoticesRemainders />}
      ></Route>
      <Route path="/becas" element={<Schoolarships />}></Route>
      <Route path="/carrito-compras" element={<OrderDetail />}></Route>
      <Route path="/expediente" element={<DigitalFile />}></Route>
      <Route path="/pedidos" element={<Orders />}></Route>
      <Route path="/tareas" element={<Tasks />}></Route>
      <Route path="/trayectoria" element={<EducationalPath />}></Route>
      <Route path="/uniformes" element={<Store />}></Route>
      <Route path="/*" element={<Navigate to="/" />}></Route>
    </Routes>
  );
}
