import BaseModel from './base.model';
import {
  getLocalDate,
  formatearFecha,
  isBeforeXDays,
} from './../utils/dates.util';
import Config from './../config/general-config';

class NotificationModel extends BaseModel {
  constructor(email, secretKey) {
    super(email, secretKey, true);
  }

  async addNotifications(notifications, idUser) {
    return this.db.transaction('rw', [this.db.notifications], async () => {
      // TODO: Keep in local to consult, after an automatic process will earse older registers
      // await this.db.notifications.where('idUsuario').equals(idUser).delete();
      await this.db.notifications.bulkPut(notifications);
    });
  }

  readNotifications(idUser) {
    return this.db.notifications.where('idUsuario').equals(idUser).toArray();
  }

  markAsRead(idNotification, idUser) {
    return this.db.notifications
      .where({ idNotificacion: idNotification, idUsuario: idUser })
      .modify({
        read: true,
        fechaLectura: formatearFecha(getLocalDate(), '/'),
      });
  }

  async deleteOldNotifications() {
    let itemsToDelete = await this.db.notifications.toArray();
    itemsToDelete = itemsToDelete.filter((item) =>
      isBeforeXDays(item.fecha, Config.DELETE_NOTIFICATION_AFTER_DAYS)
    );
    let keysToDelete = itemsToDelete.map((item) => item.idNotificacion);
    return await this.db.notifications.bulkDelete(keysToDelete);
  }
}

export default NotificationModel;
