import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { showLoader, hideLoader } from '../stores/slices/loaderSlice';
import educationalPathService from './../services/educationalPathService';
import EducationalPathController from './../controllers/educationlPath.controller';
import { showToast, TYPE_ICON } from '../helpers/sweetAlert.helper';

const useEducationalPath = (initialValue) => {
  const { isOnline } = useSelector((state) => state.internet);
  const [educationalPath, setEducationalPath] = useState(initialValue ?? []);
  const activeStudent = useSelector((state) => state.student);
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();

  useEffect(() => {
    const educationalPathController = new EducationalPathController(
      user.email,
      user.keyDecrypt
    );
    const fetchPath = async () => {
      if (!activeStudent.id) return;
      let path = await educationalPathService.getEducationalPath(
        activeStudent.id
      );
      if (!path) {
        dispatch(hideLoader());
        return;
      }
      path = path.map((item) => ({
        ...item,
        key: `${item.idGrado}-${activeStudent.id}`,
      }));
      educationalPathController.addPath(path, activeStudent.id);
      setEducationalPath(path);
      dispatch(hideLoader());
    };

    dispatch(showLoader());

    if (isOnline) {
      fetchPath();
    } else {
      educationalPathController
        .readPath(activeStudent.id)
        .then((path) => {
          if (path.length === 0 && !isOnline) {
            showToast(
              'Conexión necesaria',
              'Para llevar a cabo la primera descarga de datos es necesaria una conexión a internet. Intente nuevamente una vez que su dispositivo tenga conexión.',
              TYPE_ICON.WARNING
            );
          } else if (path.length === 0 && isOnline) {
            fetchPath();
          } else {
            setEducationalPath(path);
          }
        })
        .catch((e) =>
          showToast(
            'Error',
            'No se ha podido leer la información de la trayectoria educativa',
            TYPE_ICON.ERROR
          )
        )
        .finally(() => {
          dispatch(hideLoader());
        });
    }
  }, [activeStudent.id, dispatch, user.email, user.keyDecrypt, isOnline]);

  return educationalPath;
};

export default useEducationalPath;
