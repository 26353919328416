import React, { useMemo } from 'react';
import DOMPurify from 'dompurify';

export default function SVGIconFromText({ tagText }) {
  const textSanitized = useMemo(
    () => DOMPurify.sanitize(tagText.replace(/clip-path=".*"/g, '')),
    [tagText]
  );

  return (
    <div
      className="svg-icon-from-text"
      dangerouslySetInnerHTML={{ __html: textSanitized }}
    />
  );
}
