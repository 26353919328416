import React, { useEffect, useState } from 'react';
import TitleModule from './../core/TitleModule';
import './../../styles/components/Store.scss';
import StoreOptions from '../StoreOptions';
import { Container } from 'react-bootstrap';
import storeService from './../../services/storeService';
import { useSelector, useDispatch } from 'react-redux';
import { showLoader, hideLoader } from '../../stores/slices/loaderSlice';
import OnlineContent from '../core/OnlineContent';
import StoreProducts from '../StoreProducts';
import NoContent from '../generic/NoContent';

export default function Store() {
  const [products, setProducts] = useState([]);
  const institution = useSelector((state) => state.institution);
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchProducts = async () => {
      dispatch(showLoader());
      const _products = await storeService.listProducts(institution.id);
      setProducts(_products);
      dispatch(hideLoader());
    };
    fetchProducts();
  }, [institution, dispatch]);

  return (
    <React.Fragment>
      <TitleModule title="Uniformes y útiles escolares" />
      <NoContent
        thereAreContent={products?.length > 0}
        title="La institución aún no cuenta con productos registrados"
      >
        <div className="store h-100 pt-3">
          <Container>
            <h6 className="text-uppercase text-primary monserrat-bold-font text-center text-md-start px-2">
              Catálogo de uniformes y útiles escolares
            </h6>
            <StoreOptions />
          </Container>
          <OnlineContent>
            <StoreProducts products={products} />
          </OnlineContent>
        </div>
      </NoContent>
    </React.Fragment>
  );
}
