import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isOpen: false,
  active: {
    label: '',
    id: '',
  },
};

export const menuSlice = createSlice({
  name: 'menu',
  initialState,
  reducers: {
    toggleOpen: (state) => {
      state.isOpen = !state.isOpen;
    },
    updateActiveMenu: (state, action) => {
      state.active = action.payload;
    },
  },
});

export const { toggleOpen, updateActiveMenu } = menuSlice.actions;
export default menuSlice.reducer;
