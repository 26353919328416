import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import login_background from '../../assets/imgs/login_background.png';
import logo_big from '../../assets/imgs/logo_sac_big.svg';
import LoginForm from '../LoginForm';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import Config from './../../config/general-config';
import './../../styles/components/Login.scss';
import logo_sac_white from './../../assets/imgs/logo_sac_mid_white.svg';

export default function Login() {
  const logged = useSelector((state) => state.user.logged);
  const style = {
    backgroundImage: `url(${login_background})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'auto 100%',
    clipPath: 'polygon(0 0, 100% 0, 70% 100%, 0% 100%)',
  };
  return !logged ? (
    <Container id="login" fluid>
      <Row>
        <Col
          md={7}
          className="p-0 d-none d-md-flex background-column"
          style={style}
        >
          <div className="presentation-image-container w-100">
            <img className="logo_sac_home" src={logo_big} alt="SAC logo" />
          </div>
        </Col>
        <Col xs={12} md={5} className="form-login d-flex flex-column">
          <Row>
            <Col
              xs={12}
              className="d-flex d-md-none justify-content-center mobile-logo-container"
            >
              <img src={logo_sac_white} alt="SAC logo" />
            </Col>
            <Col
              xs={12}
              className="d-none d-md-flex justify-content-around login-links-container"
            >
              <a href="" className="login-link-header monserrat-bold-font">
                Noticias
              </a>
              <a href="" className="login-link-header monserrat-bold-font">
                Eventos
              </a>
              <a href="" className="login-link-header monserrat-bold-font">
                Novedades
              </a>
            </Col>
          </Row>

          <GoogleReCaptchaProvider reCaptchaKey={Config.captcha_key}>
            <LoginForm></LoginForm>
          </GoogleReCaptchaProvider>
        </Col>
      </Row>
    </Container>
  ) : (
    <Navigate to="/" replace="true" />
  );
}
