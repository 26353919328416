import EducationalPathModel from './../models/educationalPath.model';

class EducationalPathController {
  #model;

  constructor(email, secretKey) {
    this.#model = new EducationalPathModel(email, secretKey);
  }

  addPath(path, idStudent) {
    path = path.map((item) => ({ idAlumno: idStudent, ...item }));
    return this.#model.addPath(path, idStudent);
  }

  readPath(idStudent) {
    return this.#model.readPath(idStudent);
  }
}

export default EducationalPathController;
