import React, { useEffect, useRef } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import ErrorInputMessage from './generic/ErrorInputMessage';
import Validators from './../validators/validators';
import Config from './../config/general-config';
import { useSelector, useDispatch } from 'react-redux';
import StudentController from './../controllers/student.controller';
import { showLoader, hideLoader } from '../stores/slices/loaderSlice';
import studentService from './../services/studentService';
import {
  DURATION_TOAST,
  showToast,
  TYPE_ICON,
} from '../helpers/sweetAlert.helper';
import SyncController from '../controllers/sync.controller';

export default function SizeWeight() {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const activeStudent = useSelector((state) => state.student);
  const { isOnline } = useSelector((state) => state.internet);
  const studentController = useRef(
    new StudentController(user.email, user.keyDecrypt)
  );
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();
  const { banderaCambios: allowModifications } = useSelector(
    (state) => state.institution.config
  );

  const onSubmit = async (data) => {
    if (!allowModifications) {
      showToast(
        'Modificaciones deshabilitadas',
        'La institución ha deshabilitado la actualización de datos del alumno',
        TYPE_ICON.INFO
      );
      return;
    }
    try {
      dispatch(showLoader());
      const { talla: size, peso: weight } = data;
      if (isOnline) {
        const response = await studentService.updateSizeWeight(
          activeStudent.id,
          size,
          weight
        );
        if (!response)
          throw Error('No se ha podido actualizar el peso y talla del alumno');
      } else {
        const syncController = new SyncController(user.email, user.keyDecrypt);
        await syncController.putUpdateDataAction({
          idAlumno: activeStudent.id,
          idUsuario: user.id,
          archivo: null,
          tipo: Config.SYNC_ACTIONS.UPDATE_SIZE_WEIGHT,
          idDocumento: null,
          mimeType: null,
          idNotificacion: null,
          extraData: { size, weight },
        });
      }
      await studentController.current.updateSizeWeight(
        activeStudent.id,
        size,
        weight
      );
      showToast(
        'Actualización correcta',
        'El peso y la talla del alumno se ha actualizado correctamente',
        TYPE_ICON.SUCCESS
      );
    } catch (e) {
      showToast(
        'Error',
        'No se ha podido actualizar el peso y talla del alumno. Recargue la página e intente de nuevo. Si el problema persiste contacte a la institución',
        TYPE_ICON.ERROR,
        DURATION_TOAST.INFINITE
      );
    } finally {
      dispatch(hideLoader());
    }
  };

  useEffect(() => {
    const getSizeWeight = async () => {
      try {
        const { weight, size } = await studentController.current.readSizeWeight(
          activeStudent.id
        );
        setValue('peso', weight);
        setValue('talla', size);
      } catch (e) {
        showToast(
          'Error',
          'No se ha podido recuperar el peso y talla del alumno. Recargue la página e intente de nuevo. Si el problema persiste contacte a la institución',
          TYPE_ICON.ERROR
        );
      }
    };
    getSizeWeight();
  }, [activeStudent.id, user.email, user.keyDecrypt, setValue]);

  return (
    <div className="size-weight mb-3">
      <p className="text-primary font-bold">Talla y peso del alumno</p>
      <div className="size-weight-form">
        <form onSubmit={handleSubmit(onSubmit)}>
          <Row>
            <Col xs={12} md={6} className="mb-2">
              <div className="input-control">
                <label htmlFor="txt-talla" className="text-gray">
                  Talla:
                </label>
                <div className="input-control-suffix">
                  <input
                    id="text-talla"
                    className={`d-block w-100 ${
                      errors.talla ? 'has-error' : ''
                    }`}
                    maxLength="4"
                    placeholder="Talla"
                    {...register('talla', {
                      maxLength: 4,
                      valueAsNumber: true,
                      pattern: {
                        value: /^(0|[1-9]\d*)(\.\d{1, 2})?$/,
                      },
                      validate: Validators.Size,
                    })}
                  ></input>
                  <span className="suffix">m</span>
                </div>
                <ErrorInputMessage
                  show={errors.talla}
                  message={`La talla capturada no es válida. Los valores permitidos deben estar entre ${Config.MIN_SIZE} m y ${Config.MAX_SIZE} m`}
                  classes="text-orange"
                />
              </div>
            </Col>
            <Col xs={12} md={6} className="mb-2">
              <div className="input-control">
                <label htmlFor="txt-peso" className="text-gray">
                  Peso:
                </label>
                <div className="input-control-suffix">
                  <input
                    id="text-peso"
                    className={`d-block w-100 ${
                      errors.peso ? 'has-error' : ''
                    }`}
                    maxLength="6"
                    placeholder="Peso"
                    {...register('peso', {
                      maxLength: 6,
                      valueAsNumber: true,
                      pattern: {
                        value: /^(0|[1-9]\d*)(\.\d{1, 2})?$/,
                      },
                      validate: Validators.Weight,
                    })}
                  ></input>
                  <span className="suffix">kg</span>
                </div>
              </div>
              <ErrorInputMessage
                show={errors.peso}
                message={`El peso capturado no es válido. Los valores permitidos deben estar entre ${Config.MIN_WEIGHT} kg y ${Config.MAX_WEIGHT} kg`}
                classes="text-orange"
              />
            </Col>
            <Col xs={12} className="text-center my-3">
              {allowModifications && (
                <button className="btn bg-orange text-white">Guardar</button>
              )}
            </Col>
          </Row>
        </form>
      </div>
    </div>
  );
}
