import React, { useRef, useState, useEffect } from 'react';
import defaultPhoto from './../assets/imgs/default-photo.svg';
import './../styles/components/UploadPhotoDigitalFile.scss';
import Config from '../config/general-config';
import { useSelector, useDispatch } from 'react-redux';
import digitalFileService from './../services/digitalFileService';
import { showLoader, hideLoader } from './../stores/slices/loaderSlice';
import { updatePhoto } from './../stores/slices/studentSlice';
import StudentController from './../controllers/student.controller';
import { showToast, TYPE_ICON } from '../helpers/sweetAlert.helper';
import { getExtentionFromMimeType, calculateSizeOfFile } from '../utils/utils';
import SyncController from './../controllers/sync.controller';

export default function UploadPhotoDigitalFile({ photo }) {
  const inputRef = useRef(null);
  const activeStudent = useSelector((state) => state.student);
  const user = useSelector((state) => state.user);
  const [currentPhoto, setCurrentPhoto] = useState();
  const dispatch = useDispatch();
  const { isOnline } = useSelector((state) => state.internet);
  const { banderaCambios: allowModifications } = useSelector(
    (state) => state.institution.config
  );

  const handleUpload = () => {
    if (!allowModifications) {
      showToast(
        'Modificaciones deshabilitadas',
        'La institución ha deshabilitado la carga de documentos',
        TYPE_ICON.INFO
      );
      resetFileInput();
      return;
    }
    inputRef.current?.click();
  };
  const resetFileInput = () => {
    inputRef.current.value = null;
  };
  const previewPhoto = (event) => {
    const selectedImage = event.target.files[0];
    if (!selectedImage) {
      return;
    }
    if (
      !Config.validImagesExtentions.includes(
        getExtentionFromMimeType(selectedImage.type)
      )
    ) {
      showToast(
        'Archivo inválido',
        'El archivo seleccionado es inválido',
        TYPE_ICON.WARNING
      );
      resetFileInput();
      return;
    }
    if (
      calculateSizeOfFile(selectedImage.size, 'MB') > Config.MAX_IMG_SIZE_MB
    ) {
      showToast(
        'Archivo inválido',
        `El archivo seleccionado tiene un peso mayor a ${Config.MAX_IMG_SIZE_MB} Mb`,
        TYPE_ICON.WARNING
      );
      resetFileInput();
      return;
    }
    const reader = new FileReader();
    reader.readAsDataURL(selectedImage);
    reader.onloadend = () => {
      setCurrentPhoto(reader.result);
    };
  };

  const uploadPhoto = async (event) => {
    try {
      const fileImg = inputRef.current.files[0];
      if (!fileImg) {
        return;
      }
      dispatch(showLoader());
      const formData = new FormData();
      // INFO: Name of data doesn't matter
      formData.append('photo', fileImg);
      if (isOnline) {
        const response = await digitalFileService.uploadStudentPhoto(
          activeStudent.id,
          formData
        );
        if (!response)
          throw Error('No se ha podido actualizar la foto del alumno');
      } else {
        const syncController = new SyncController(user.email, user.keyDecrypt);
        await syncController.putFileAction({
          idAlumno: activeStudent.id,
          idUsuario: user.id,
          archivo: fileImg,
          tipo: Config.SYNC_ACTIONS.UPLOAD_PHOTO,
          idDocumento: null,
          mimeType: fileImg.type,
          idNotificacion: null,
          extraData: null,
        });
      }
      const studentController = new StudentController(
        user.email,
        user.keyDecrypt
      );
      await studentController.updatePhotoStudent(
        activeStudent.id,
        currentPhoto
      );
      dispatch(updatePhoto(currentPhoto));
      resetFileInput();
      showToast(
        'Éxito',
        'La foto ha sido actualizada exitósamente',
        TYPE_ICON.SUCCESS
      );
    } catch (e) {
      showToast(
        'Error',
        'No se ha podido actualizar la imagen del alumno. Intente nuevamente por favor.',
        TYPE_ICON.ERROR
      );
    } finally {
      dispatch(hideLoader());
    }
  };

  useEffect(() => {
    setCurrentPhoto(activeStudent.avatar);
  }, [activeStudent]);

  return (
    <div className="upload-photo-digital-file">
      <div className="photo-container">
        <div className="photo">
          <img
            src={currentPhoto ?? defaultPhoto}
            alt="Fotografía del alumno"
            className="cursor-pointer"
            onClick={handleUpload}
          />
          <div className="photo-footer cursor-pointer">
            <span onClick={uploadPhoto} className="d-block">
              Subir
            </span>
            <input
              type="file"
              name="foto_alumno"
              id="foto_alumno"
              hidden
              ref={inputRef}
              accept={Config.validImagesExtentions}
              onChange={previewPhoto}
            />
          </div>
        </div>
      </div>
      <div className="photo-instructions mx-auto mt-2">
        <span className="font-italic">
          Agrega una fotografía en formato PNG o JPG, de frente con un fondo
          blanco y un peso máximo de 2 MB.
        </span>
      </div>
    </div>
  );
}
