import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Login from '../components/pages/Login';
import RouterOutletPrivado from './../components/core/RouterOutletPrivado';

export default function RouterApp() {
  return (
    <Routes>
      <Route path="/login" element={<Login />}></Route>
      <Route path="/*" element={<RouterOutletPrivado />}></Route>
    </Routes>
  );
}
