import React, { useCallback } from 'react';
import './../styles/components/Logout.scss';
import { useDispatch } from 'react-redux';
import { logout } from '../stores/slices/userSlice';
import useInternet from '../hooks/useInternet';
import { confirmAction } from '../helpers/sweetAlert.helper';

export default function Logout() {
  const { isOnline } = useInternet();
  const dispatch = useDispatch();

  const closeSession = useCallback(async () => {
    const exit = await confirmAction(
      '¿Está seguro que desea salir?',
      'Su sesión será cerrada'
    );
    if (exit) {
      dispatch(logout());
    }
  }, [dispatch]);

  return (
    <div className="logout cursor-pointer font-bold" onClick={closeSession}>
      <i
        className={
          'fa fa-power-off ' + (isOnline ? 'text-orange' : 'text-gray-3')
        }
      ></i>
    </div>
  );
}
