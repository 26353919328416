import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { showLoader, hideLoader } from '../stores/slices/loaderSlice';
import { showToast, TYPE_ICON } from '../helpers/sweetAlert.helper';
import InstitutionController from './../controllers/institution.controller';
import institutionService from './../services/institutionService';
import { updateConfig } from './../stores/slices/institutionSlice';

const useConfig = () => {
  const { isOnline } = useSelector((state) => state.internet);
  const [config, setConfig] = useState({ banderaCambios: false });
  const institution = useSelector((state) => state.institution);
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();

  useEffect(() => {
    const institutionController = new InstitutionController(
      user.email,
      user.keyDecrypt
    );
    const fetchConfig = async () => {
      if (!institution.id) return;
      const _config = await institutionService.getConfig(institution.id);
      if (!_config) {
        dispatch(hideLoader());
        return;
      }

      await institutionController.updateConfig(institution.id, _config);
      setConfig(_config);
      dispatch(updateConfig(_config));
      dispatch(hideLoader());
    };

    dispatch(showLoader());

    if (isOnline) {
      fetchConfig();
    } else {
      institutionController
        .readConfig(institution.id)
        .then((config) => {
          if (!config && !isOnline) {
            showToast(
              'Conexión necesaria',
              'Para llevar a cabo la primera descarga de datos es necesaria una conexión a internet. Intente nuevamente una vez que su dispositivo tenga conexión.',
              TYPE_ICON.WARNING
            );
          } else {
            setConfig(config);
            dispatch(updateConfig(config));
          }
        })
        .catch((e) =>
          showToast(
            'Error',
            'No se ha podido leer la información del expediente digital',
            TYPE_ICON.ERROR
          )
        )
        .finally(() => {
          dispatch(hideLoader());
        });
    }
  }, [institution.id, dispatch, user.email, user.keyDecrypt, isOnline]);

  return config;
};

export default useConfig;
