import { compareDates } from './dates.util';

export const rightStr = (str, caracteres) => {
  str = str.toString();
  return str.slice(str.length - caracteres);
};

export const isTruthy = (value) => {
  if (value === 0) {
    return true;
  }
  if (!value) {
    return false;
  }
  return true;
};

export function isEmpty(value) {
  if (typeof value === 'object') {
    for (const key in value) {
      if (value.hasOwnProperty(key)) {
        return false;
      }
    }
    return true;
  } else if (Array.isArray(value)) {
    if (value.length === 0) {
      return true;
    }
    return false;
  } else if (typeof value === 'string') {
    if (value.length === 0) {
      return true;
    }
    return false;
  } else if (
    Number.isNaN(value) ||
    typeof value === 'undefined' ||
    value === null
  ) {
    return true;
  }
  return false;
}

export function removeAccents(value) {
  return value
    .normalize('NFD')
    .replace(
      /([^n\u0300-\u036f]|n(?!\u0303(?![\u0300-\u036f])))[\u0300-\u036f]+/gi,
      '$1'
    )
    .normalize();
}

export function isNumber(value) {
  value = parseInt(value, 10);
  return typeof value === 'number' && isFinite(value);
}

export function numberMoreReadeable(value, money = false) {
  let numberToConvert;
  numberToConvert = value;
  if (!isNumber(value)) {
    numberToConvert = +value;
  }
  const mxFormat = new Intl.NumberFormat('es-MX');
  return money
    ? new Intl.NumberFormat('es-MX', {
        style: 'currency',
        currency: 'MXN',
      }).format(numberToConvert)
    : mxFormat.format(numberToConvert);
}

export function orderObjectsArray(array, propToOrder, type = 'asc') {
  array.sort((a, b) => {
    if (type === 'asc') {
      let propA = a[propToOrder];
      let propB = b[propToOrder];

      if (propA < propB) {
        return -1;
      }
      if (propA > propB) {
        return 1;
      }
      return 0;
    } else {
      let propA = a[propToOrder];
      let propB = b[propToOrder];

      if (propA < propB) {
        return 1;
      }
      if (propA > propB) {
        return -1;
      }
      return 0;
    }
  });
}

export function orderArrayByDate(array, propToOrder, type = 'ASC') {
  array.sort((a, b) => compareDates(a[propToOrder], b[propToOrder], type));
}

export function getExtentionFromMimeType(mimeType) {
  const mimeTypes = {
    'application/msword': 'doc',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
      'docx',
    'application/vnd.ms-excel': 'xls',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'xlsx',
    'application/xlsx': 'xlsx',
    'application/vnd.ms-outlook': 'msg',
    'application/vnd.ms-powerpoint': 'ppt',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation':
      'pptx',
    'application/pptx': 'pptx',
    'application/x-compressed': 'tgz',
    'application/xml': 'xml',
    'application/pdf': 'pdf',
    'application/zip': 'zip',
    'audio/mpeg': 'mp3',
    'audio/x-mpegurl': 'm3u',
    'audio/x-wav': 'wav',
    'image/bmp': 'bmp',
    'image/gif': 'gif',
    'image/jpeg': 'jpg',
    'image/svg+xml': 'svg',
    'image/tiff': 'tiff',
    'image/png': 'png',
    'image/x-icon': 'ico',
    'text/css': 'css',
    'text/html': 'html',
    'text/plain': 'txt',
    'video/mpeg': 'mpeg',
    'video/quicktime': 'mov',
    'video/x-msvideo': 'avi',
  };
  return '.' + mimeTypes[mimeType] ?? '';
}

export function b64ToBytesArray(b64) {
  const binaryString = window.atob(b64);
  const binaryLen = binaryString.length;
  const bytes = new Uint8Array(binaryLen);
  for (let i = 0; i < binaryLen; i++) {
    const ascii = binaryString.charCodeAt(i);
    bytes[i] = ascii;
  }
  return bytes;
}

export function downloadFile(params) {
  const { name, fileData, mimeType } = params;
  const ext = getExtentionFromMimeType(mimeType);
  const bytes = b64ToBytesArray(fileData);
  const blob = new Blob([bytes], { type: mimeType });
  const link = document.createElement('a');
  link.href = window.URL.createObjectURL(blob);
  link.download = `${name}${ext}`;
  link.click();
}

export function calculateSizeOfFile(size, measure = 'MB') {
  if (size <= 0) {
    return 0;
  }
  const bytesTo = {
    KB: () => size / 1024,
    MB: () => bytesTo['KB']() / 1024,
    GB: () => bytesTo['MB']() / 1024,
  };
  return bytesTo[measure]();
}

export function fileToB64(file) {
  return new Promise((resolve, reject) => {
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

export function b64ToFile(b64, mimeType) {
  const ext = getExtentionFromMimeType(mimeType);
  const bytes = b64ToBytesArray(getOnlyBytesFromb64String(b64));
  return new File([bytes], `archivo${ext}`, { type: mimeType });
}

export function getOnlyBytesFromb64String(b64) {
  return b64.split('base64,')[1];
}
