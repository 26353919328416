import React, { useState, useEffect, useRef } from 'react';
import { Container } from 'react-bootstrap';
import NoticeRemainderItem from '../NoticeRemainderItem';
import './../../styles/components/NoticesRemainders.scss';
import TitleModule from './../core/TitleModule';
import DateFormatted from '../generic/DateFormatted';
import useNotifications from './../../hooks/useNotifications';
import Config from './../../config/general-config';
import NoContent from '../generic/NoContent';
import SelectedNotice from '../SelectedNotice';
import NotificationController from './../../controllers/notification.controller';
import { useSelector, useDispatch } from 'react-redux';
import notificacionesService from './../../services/notificacionesService';
import { showToast, TYPE_ICON } from '../../helpers/sweetAlert.helper';
import SyncController from './../../controllers/sync.controller';
import { getLocalDate, formatearFecha } from './../../utils/dates.util';
import { showLoader, hideLoader } from '../../stores/slices/loaderSlice';

export default function NoticesRemainders() {
  const notifications = useNotifications([]);
  const [filteredNotifications, setFilteredNotifications] =
    useState(notifications);
  const [activeNotification, setActiveNotification] = useState(null);
  const user = useSelector((state) => state.user);
  const notificationController = useRef(
    new NotificationController(user.email, user.keyDecrypt)
  );
  const dispatch = useDispatch();

  useEffect(() => {
    let _notifications = notifications.filter(
      (notification) =>
        notification.tipoNotificacion === Config.TYPE_NOTIFICATION.REMAINDER ||
        notification.tipoNotificacion === Config.TYPE_NOTIFICATION.NOTICE
    );
    if (_notifications.length === 0) {
      return;
    }
    setActiveNotification(_notifications[0]);
    setFilteredNotifications(_notifications);
  }, [notifications]);

  const showDetailsNotice = async (notice) => {
    setActiveNotification(notice);
    if (notice.read) {
      return;
    }
    dispatch(showLoader());
    try {
      const markAsReadOnServer = await notificacionesService.readNotifications(
        user.id,
        [
          {
            idNotificacion: notice.idNotificacion,
            fechaLectura: formatearFecha(getLocalDate(), '/'),
          },
        ]
      );
      const markAsReadLocally = await notificationController.current.markAsRead(
        notice.idNotificacion,
        user.id
      );
      if (!markAsReadLocally)
        throw new Error('La notificación no se ha podido marcar como leída');

      if (!markAsReadOnServer) {
        const syncController = new SyncController(user.email, user.keyDecrypt);
        await syncController.putAction({
          idAlumno: 'na',
          archivo: null,
          tipo: Config.SYNC_ACTIONS.MARK_AS_READ,
          idDocumento: null,
          idNotificacion: notice.idNotificacion,
          idUsuario: user.id,
          mimeType: null,
          extraData: null,
        });
        showToast(
          'Sincronización pendiente',
          'La notificación se ha marcado como leída pero los cambios serán sincronizados posteriormente',
          TYPE_ICON.INFO
        );
      }

      notice.read = true;
    } catch (e) {
      showToast(
        'Error',
        'La notificación no se ha podido marcar como leída. Intente nuevamente por favor',
        TYPE_ICON.ERROR
      );
    } finally {
      dispatch(hideLoader());
    }
  };

  return (
    <React.Fragment>
      <TitleModule title={'Avisos y Recordatorios'} />
      <Container>
        <NoContent
          thereAreContent={filteredNotifications.length > 0}
          title="No existen avisos o recordatorios para mostrar"
        >
          <div className="notices-remainders">
            <DateFormatted />
            {activeNotification && (
              <SelectedNotice
                notification={activeNotification}
              ></SelectedNotice>
            )}
            <div className="notices-remainders-container">
              {filteredNotifications.map((notification) => (
                <NoticeRemainderItem
                  key={notification.idNotificacion}
                  active={
                    notification.idNotificacion ===
                    activeNotification?.idNotificacion
                  }
                  activateNotice={showDetailsNotice}
                  notification={notification}
                />
              ))}
            </div>
          </div>
        </NoContent>
      </Container>
    </React.Fragment>
  );
}
