import React, { useEffect } from 'react';
import Layout from './components/core/Layout';
import { listenBroadcastNotification } from './helpers/notifications.helper';

function App() {
  useEffect(() => {
    listenBroadcastNotification();
  }, []);
  return <Layout></Layout>;
}

export default App;
