import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import TitleModule from '../core/TitleModule';
import SchoolarshipsOptions from '../SchoolarshipsOptions';
import coverBecas from './../../assets/imgs/cover_becas.png';
import './../../styles/components/Schoolarships.scss';
import useSchoolarShips from './../../hooks/useSchoolarships';

export default function Schoolarships() {
  const schoolarships = useSchoolarShips([]);
  return (
    <React.Fragment>
      <TitleModule title="Becas" />
      <div className="becas-img-cover-container">
        <img className="becas-img-cover" src={coverBecas} alt="Becas" />
      </div>
      <Container className="schoolarships pt-4 px-md-0">
        <Row>
          <Col xs={12}>
            <div className="schoolarship-text verdana-font mb-4">
              Consúlte los apoyos que ofrecemos para asegurar su acceso a la
              educación y participe en las convocatorias publicadas cumpliendo
              con los requisitos solicitados.
            </div>
          </Col>
          <Col xs={12}>
            <SchoolarshipsOptions schoolarships={schoolarships} />
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
}
