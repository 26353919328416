import Dexie from 'dexie';
import { applyEncryptionMiddleware } from 'dexie-encrypted';
import { md5 } from '../utils/md5.util';
import {
  IDBEncryptedStoresUser,
  IDBStoresUser,
  IDBStoresData,
  IDBEncryptedStoresData,
} from './IDBStores';
import Validators from './../validators/validators';
import {
  inputData,
  DURATION_TOAST,
  showToast,
  TYPE_ICON,
} from '../helpers/sweetAlert.helper';

function connectToIDB(email, secretKey, isData = false) {
  const database = email + (isData ? '-data' : '');
  const encoder = new TextEncoder();
  const hash = md5(secretKey);
  const symmetricKey = encoder.encode(hash);

  const db = new Dexie(database);
  let store = IDBStoresUser;
  let encryptedStore = IDBEncryptedStoresUser;
  if (isData) {
    store = IDBStoresData;
    encryptedStore = IDBEncryptedStoresData;
  }
  applyEncryptionMiddleware(db, symmetricKey, encryptedStore);
  db.version(1).stores(store);

  return db;
}

async function forgotPassword() {
  try {
    const user = await inputData(
      'Olvidé mi contraseña',
      Validators.Email,
      'Por favor confirme su usuario'
    );
    await Dexie.delete(user);
    return true;
  } catch (error) {
    showToast(
      'Error',
      'No es posible iniciar el proceso de reseteo de contraseña. Intente nuevamente por favor.',
      TYPE_ICON.ERROR,
      DURATION_TOAST.INFINITE
    );
    return false;
  }
}

function userExists(email) {
  const database = email;
  return Dexie.exists(database);
}

export { connectToIDB as default, userExists, forgotPassword };
