import React, { useEffect, useState } from 'react';
import { Container, Col, Row } from 'react-bootstrap';
import TitleModule from '../core/TitleModule';
import './../../styles/components/EducationalPath.scss';
import disabled_1 from '../../assets/imgs/1_disabled.svg';
import disabled_2 from '../../assets/imgs/2_disabled.svg';
import disabled_3 from '../../assets/imgs/3_disabled.svg';
import progress_1 from '../../assets/imgs/1_progress.svg';
import progress_2 from '../../assets/imgs/2_progress.svg';
import progress_3 from '../../assets/imgs/3_progress.svg';
import finished_1 from '../../assets/imgs/1_finished.svg';
import finished_2 from '../../assets/imgs/2_finished.svg';
import finished_3 from '../../assets/imgs/3_finished.svg';
import FormativeFields from '../FormativeFields';
import useEducationalPath from './../../hooks/useEducationalPath';

const getImgFromGrade = (item, idGrade) => {
  const disabled = [disabled_1, disabled_2, disabled_3];
  if (!item) {
    return disabled[idGrade];
  }
  idGrade -= 1; // INFO: To match index with grade number
  const progress = [progress_1, progress_2, progress_3];
  if (item.estatusAlumnoGrupo) {
    return progress[idGrade];
  }
  const finished = [finished_1, finished_2, finished_3];
  return finished[idGrade];
};

export default function EducationalPath() {
  const path = useEducationalPath([]);
  const [activeGrade, setActiveGrade] = useState(null);
  const [mappedPath, setMappedPath] = useState([]);

  useEffect(() => {
    let _mappedPath = [];
    for (let i = 1; i < 4; i++) {
      _mappedPath.push(path.find((item) => item.idGrado === i + ''));
    }
    setMappedPath(_mappedPath);
    const activeGrade = path.find((item, index) => item.estatusAlumnoGrupo);
    setActiveGrade(activeGrade);
  }, [path]);

  return (
    <React.Fragment>
      <TitleModule title="Trayectoria Educativa" />
      <Container className="mt-4">
        <Row className="mb-2">
          <Col className="">
            <div className="verdana-font text-center description-path">
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Eaque,
              culpa, dolore voluptatem necessitatibus amet est quos iusto quae
              dolorem perferendis a et consequatur molestiae maxime cum.
              Exercitationem porro earum dolore.
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <div className="alert alert-info">
              <div className="text-primary">
                <span className="font-bold">Tiene beca: </span>
                <span>{activeGrade?.tieneBeca ? 'SÍ' : 'NO'}</span>
              </div>
              {activeGrade?.tieneBeca && (
                <div className="text-primary">
                  <span className="font-bold">Beca: </span>
                  <span>
                    {activeGrade?.becaAlumno?.nombreTipoBeca}&nbsp;
                    <span>({activeGrade?.becaAlumno?.porcentaje}%)</span>
                  </span>
                </div>
              )}
            </div>
          </Col>
        </Row>
        <Row className="text-center my-4">
          {mappedPath.map((item, index) => {
            return item ? (
              <Col key={item.key}>
                <div
                  className={`img-grade-container ${
                    item.idGrado === activeGrade?.idGrado ? 'active' : ''
                  }`}
                >
                  <img
                    className="cursor-pointer"
                    src={getImgFromGrade(item, item.idGrado)}
                    alt="Estatus ciclo escolar"
                    onClick={() => {
                      setActiveGrade(item);
                    }}
                  />
                </div>
              </Col>
            ) : (
              <Col key={index}>
                <div className="img-grade-container">
                  <img
                    className=""
                    src={getImgFromGrade(item, index)}
                    alt="Estatus ciclo escolar"
                  />
                </div>
              </Col>
            );
          })}
        </Row>
        <Row className="text-center mb-3">
          <Col xs={12} md={{ span: 6, offset: 3 }}>
            <Row>
              <Col xs={12} className="mb-3">
                <span className="text-uppercase text-primary monserrat-bold-font">
                  {activeGrade?.nombreCicloEscolar}
                </span>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <FormativeFields fields={activeGrade?.materias ?? []} />
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
}
