import BaseModel from './base.model';

class EducationalPathModel extends BaseModel {
  constructor(email, secretKey) {
    super(email, secretKey, true);
  }

  async addPath(path, idStudent) {
    return this.db.transaction('rw', [this.db.path], async () => {
      await this.db.path.where('idAlumno').equals(idStudent).delete();
      await this.db.path.bulkAdd(path);
    });
  }

  readPath(idStudent) {
    return this.db.path.where('idAlumno').equals(idStudent).toArray();
  }
}

export default EducationalPathModel;
