import useInternet from './useInternet';
import { useEffect, useState } from 'react';
import menuBoardService from './../services/menuBoardService';
import MenuController from '../controllers/menu.controller';
import { useSelector } from 'react-redux';
import { showToast, TYPE_ICON } from '../helpers/sweetAlert.helper';

const useMenu = () => {
  const { isOnline } = useInternet();
  const [menu, setMenu] = useState([]);
  const user = useSelector((state) => state.user);

  useEffect(() => {
    let _menu = [];
    const fetchMenu = async () => {
      try {
        _menu = await menuBoardService();
        setMenu(_menu);
      } catch (e) {
        showToast(
          'Error',
          'No se ha podido recuperar la información del menú',
          TYPE_ICON.ERROR
        );
      }
    };
    const readMenu = async () => {
      try {
        const menuController = new MenuController(user.email, user.keyDecrypt);
        _menu = await menuController.readMenu();
        setMenu(_menu);
      } catch (e) {
        showToast(
          'Error',
          'No se ha podido recuperar la información del menú',
          TYPE_ICON.ERROR
        );
      }
    };
    if (isOnline) {
      fetchMenu();
    } else {
      readMenu();
    }
  }, [isOnline, user]);

  return menu;
};

export default useMenu;
