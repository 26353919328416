import Config from './../config/general-config';
class Validators {
  static Email = (value, unicode = true) => {
    let regExp =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (unicode) {
      regExp =
        /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    }
    return regExp.test(String(value).toLowerCase());
  };
  static Required = (value) => {
    if (value === 0) {
      return true;
    }
    if (!value) {
      return false;
    }
    return true;
  };
  static Size = (value) => value >= Config.MIN_SIZE && value <= Config.MAX_SIZE;
  static Weight = (value) =>
    value >= Config.MIN_WEIGHT && value <= Config.MAX_WEIGHT;
}
export default Validators;
