import baseService from './baseService';
import Config from './../config/general-config';
import wsResponseHandler from './../handlers/wsResponse.hanlder';
import { getToken } from '../helpers/localstorage.helper';

const digitalFileService = {
  getDigitalFile: async (idAlumno) => {
    const response = await baseService.getRequest(
      Config.ENDPOINTS.DIGITAL_FILE,
      {
        _idAlumno: idAlumno,
      },
      {
        Authorization: `Bearer ${getToken()}`,
      }
    );
    return wsResponseHandler(response);
  },
  uploadStudentPhoto: async (idAlumno, data, config) => {
    const response = await baseService.postRequest(
      `${Config.ENDPOINTS.DIGITAL_FILE}/cargarFotoAlumno?_idAlumno=${idAlumno}`,
      data,
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
          'Content-Type': 'multipart/form-data',
        },
      }
    );
    return response.estatus;
  },
  uploadDocument: async (idAlumno, idDocumento, file) => {
    const response = await baseService.postRequest(
      `${Config.ENDPOINTS.UPLOAD_DOCUMENT}?_idAlumno=${idAlumno}&_idDocumento=${idDocumento}`,
      file,
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
          'Content-Type': 'multipart/form-data',
        },
      }
    );
    return wsResponseHandler(response);
  },
  viewDocument: async (idFile) => {
    const response = await baseService.getRequest(
      Config.ENDPOINTS.VIEW_DOCUMENT,
      {
        _idArchivo: idFile,
      },
      {
        Authorization: `Bearer ${getToken()}`,
      }
    );
    return wsResponseHandler(response);
  },
};

export default digitalFileService;
