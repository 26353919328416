import NotificationModel from './../models/notification.model';

class NotificationController {
  #model;

  constructor(email, secretKey) {
    this.#model = new NotificationModel(email, secretKey);
  }

  addNotifications(notifications, idUser) {
    return this.#model.addNotifications(notifications, idUser);
  }

  readNotifications(idUser) {
    return this.#model.readNotifications(idUser);
  }

  markAsRead(idNotification, idUser) {
    return this.#model.markAsRead(idNotification, idUser);
  }
  deleteOldNotifications() {
    return this.#model.deleteOldNotifications();
  }
}

export default NotificationController;
