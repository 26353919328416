import React from 'react';
import FormativeField from './FormativeField';

export default function FormativeFields({ fields }) {
  return (
    <div className="formative-fields-container my-4">
      {fields.map((field) => (
        <FormativeField field={field} key={field.idMateria}></FormativeField>
      ))}
    </div>
  );
}
