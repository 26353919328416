import React, { useEffect, useState } from 'react';
import storeService from '../../services/storeService';
import { useSelector, useDispatch } from 'react-redux';
import TitleModule from './../core/TitleModule';
import { Container } from 'react-bootstrap';
import OrderConfirmed from '../OrderConfirmed';
import { showLoader, hideLoader } from '../../stores/slices/loaderSlice';
import { orderObjectsArray } from './../../utils/utils';
import OnlineContent from '../core/OnlineContent';

export default function Orders() {
  const user = useSelector((state) => state.user);
  const [orders, setOrders] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchOrders = async () => {
      dispatch(showLoader());
      let _orders = await storeService.listOrders(user.idPadre);
      orderObjectsArray(_orders, 'idSolicitud', 'desc');
      setOrders(_orders);
      dispatch(hideLoader());
    };
    fetchOrders();
  }, [user, dispatch]);
  return (
    <React.Fragment>
      <TitleModule title="Estatus de Pedidos" />
      <Container className="mt-3">
        <OnlineContent>
          <div className="orders">
            <div className="orders-container">
              {orders.map((order) => (
                <OrderConfirmed order={order} key={order.idSolicitud} />
              ))}
            </div>
          </div>
        </OnlineContent>
      </Container>
    </React.Fragment>
  );
}
