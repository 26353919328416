import React, { useState } from 'react';
import './../styles/components/SchoolarshipsOptions.scss';
import { ReactComponent as SchoolarshipInfoIcon } from './../assets/imgs/beca_info.svg';
// import { ReactComponent as SchoolarshipMoneyIcon } from './../assets/imgs/beca_dinero.svg';
import FileViewer from './generic/FileViewer';
import { useDispatch, useSelector } from 'react-redux';
import { showLoader, hideLoader } from '../stores/slices/loaderSlice';
import InstitutionController from './../controllers/institution.controller';
import schoolarshipsService from './../services/schoolarshipsService';
import {
  showToast,
  TYPE_ICON,
  DURATION_TOAST,
} from '../helpers/sweetAlert.helper';

export default function SchoolarshipsOptions({ schoolarships }) {
  const [previewFile, setPreviewFile] = useState(null);
  const dispatch = useDispatch();
  const { isOnline } = useSelector((state) => state.internet);
  const user = useSelector((state) => state.user);
  const institution = useSelector((state) => state.institution);

  const handleClick = async (data) => {
    try {
      dispatch(showLoader());

      const schoolarshipsController = new InstitutionController(
        user.email,
        user.keyDecrypt
      );
      let _file = null;
      if (isOnline) {
        const response = await schoolarshipsService.viewDocument(
          data.archivo.idArchivo,
          institution.id
        );
        if (!response) {
          showToast(
            'Documento no encontrado',
            'No se ha encontrado el documento en el servidor o no ha sido cargado',
            TYPE_ICON.WARNING
          );
          return;
        }
        _file = {
          name: data.nombreDocumento,
          fileData: response.archivoBytes,
          mimeType: response.tipoArchivo,
        };
        await schoolarshipsController.updateSchoolarShipFile({
          idInstitucion: institution.id,
          idDocumento: data.idDocumento,
          file: _file,
        });
      } else {
        _file = await schoolarshipsController.readSchoolarshipFile(
          institution.id,
          data.idDocumento
        );
        if (!_file) {
          showToast(
            'Documento no almacenado localmente',
            'No se cuenta con una copia local del archivo para visualizarse sin conexión. Las copias locales se almacenan cuando el archivo se visualiza al menos una vez con conexión a internet activa',
            TYPE_ICON.INFO,
            DURATION_TOAST.INFINITE
          );
          return;
        }
      }
      if (_file) {
        setPreviewFile(_file);
      }
    } catch (e) {
      console.log(e);
      showToast(
        'Error cargando documento',
        'No se ha podido cargar el documento. Recargue la página e intente nuevamente por favor',
        TYPE_ICON.ERROR
      );
    } finally {
      dispatch(hideLoader());
    }
  };
  return (
    <React.Fragment>
      <FileViewer file={previewFile} />
      <div className="schoolarships-options">
        <div className="schoolarships-options-container d-flex">
          {schoolarships.map((item) => {
            return (
              <div
                className="schoolarship-option"
                onClick={() => handleClick(item)}
                key={item.idDocumento}
              >
                <SchoolarshipInfoIcon />
                <span>{item.nombreDocumento}</span>
                <small className="schoolarship-description f-10px">
                  {item.descripcionDocumento}
                </small>
              </div>
            );
          })}
        </div>
      </div>
    </React.Fragment>
  );
}
