import React, { useEffect, useCallback } from 'react';
import { useForm } from 'react-hook-form';
import ErrorInputMessage from './generic/ErrorInputMessage';
import Validators from './../validators/validators';
import { useDispatch } from 'react-redux';
import { login } from '../stores/slices/userSlice';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { isTruthy } from './../utils/utils';
import './../styles/components/LoginForm.scss';
import Auth from '../controllers/auth.controller';
import { showToast, TYPE_ICON } from '../helpers/sweetAlert.helper';
import { loginInstitution } from '../stores/slices/institutionSlice';
import { showLoader, hideLoader } from '../stores/slices/loaderSlice';
import { updateActiveStudent } from '../stores/slices/studentSlice';
import useInternet from './../hooks/useInternet';
import { setToken } from '../helpers/localstorage.helper';
import { loadCart } from './../stores/slices/carSlice';
import { forgotPassword } from '../db/IDB';

export default function LoginForm() {
  const { executeRecaptcha } = useGoogleReCaptcha();
  const dispatch = useDispatch();
  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const { isOnline } = useInternet();

  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      return;
    }
    const token = await executeRecaptcha('login');
    if (!token) {
      showToast(
        'Error Captcha',
        'No es posible verificar el captcha, recargue la página por favor.',
        TYPE_ICON.WARNING
      );
      return;
    }
    return isTruthy(token);
  }, [executeRecaptcha]);

  const onSubmit = async (data) => {
    dispatch(showLoader());
    const valid = handleReCaptchaVerify();
    if (!valid) {
      dispatch(hideLoader());
      return;
    }
    const auth = new Auth();
    const loginData = await auth.authenticate(
      data.usuario,
      data.password,
      isOnline
    );

    if (!loginData) {
      reset();
      dispatch(hideLoader());
      return;
    }
    const { padre_tutor, institucion, token } = loginData;
    const activeStudent = padre_tutor.alumnos[0];
    dispatch(login(padre_tutor));
    activeStudent && dispatch(updateActiveStudent(activeStudent));
    dispatch(loginInstitution(institucion));
    dispatch(hideLoader());
    dispatch(loadCart());
    setToken(token.token);
  };

  useEffect(() => {
    handleReCaptchaVerify();
  }, [handleReCaptchaVerify]);

  const handleForgotPassword = async () => {
    const success = await forgotPassword();
    if (success) {
      // Redirect to reset password page
    }
  };

  return (
    <div className="login-form-container flex-grow-1 d-flex justify-content-center align-items-center">
      <form onSubmit={handleSubmit(onSubmit)}>
        <h2 className="arvo-font f-20px titles-login">Inicio de sesión</h2>
        <p className="titles-login mb-5">
          Inicio de sesión para padres de familia
        </p>
        <div className="input-control txt-usuario">
          <input
            type="text"
            className={`d-block w-100 input-login ${
              errors.usuario ? 'has-error' : ''
            }`}
            placeholder="Usuario"
            {...register('usuario', {
              validate: (value) => Validators.Email(value),
            })}
          ></input>
          <ErrorInputMessage
            show={errors.usuario}
            message="El usuario no tiene un formato válido"
            classes="text-orange"
          />
        </div>
        <div className="input-control txt-pass">
          <input
            type="password"
            className={`d-block w-100 input-login ${
              errors.password ? 'has-error' : ''
            }`}
            placeholder="Contraseña"
            {...register('password', { required: true })}
          ></input>
          <ErrorInputMessage
            show={errors.password}
            message="Ingresa la contraseña"
            classes="text-orange"
          />
        </div>
        <button
          type="submit"
          className="btn-login d-block btn font-bold mx-auto scale-hover"
        >
          Entrar
        </button>
        <span
          className="d-block forgot-password-link f-14px text-center cursor-pointer"
          onClick={handleForgotPassword}
        >
          Olvidé mi contraseña
        </span>
      </form>
    </div>
  );
}
