import baseService from './baseService';
import Config from './../config/general-config';
import wsResponseHandler from './../handlers/wsResponse.hanlder';
import { getToken } from '../helpers/localstorage.helper';

const schoolarshipsService = {
  getDocuments: async (idSchool) => {
    const response = await baseService.getRequest(
      Config.ENDPOINTS.SCHOOLARSHIPS_DOCS,
      {
        _idEscuela: idSchool,
      },
      {
        Authorization: `Bearer ${getToken()}`,
      }
    );
    return wsResponseHandler(response);
  },
  viewDocument: async (idFile, idSchool) => {
    const response = await baseService.getRequest(
      Config.ENDPOINTS.VIEW_DOCUMENT_SCHOOLARSHIPS,
      {
        _idArchivo: idFile,
        _idEscuela: idSchool,
      },
      {
        Authorization: `Bearer ${getToken()}`,
      }
    );
    return wsResponseHandler(response);
  },
};

export default schoolarshipsService;
