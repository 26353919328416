import authService from '../services/authService';
import {
  showToast,
  TYPE_ICON,
  DURATION_TOAST,
} from '../helpers/sweetAlert.helper';
import UserController from './user.controller';
import { userExists } from './../db/IDB';
import InstitutionController from './institution.controller';
import StudentController from './student.controller';
import menuBoardService from './../services/menuBoardService';
import MenuController from './menu.controller';

class Auth {
  authenticate(userName, pass, isOnline) {
    if (isOnline) {
      return this.#onlineAuth(userName, pass);
    }
    return this.#offlineAuth(userName, pass);
  }

  async #onlineAuth(userName, pass) {
    try {
      const response = await authService(userName, pass);

      if (!response) {
        showToast(
          'Error desconocido',
          'Ha ocurrido un error desconocido. Intente de nuevo más tarde o contacte al administrador del sistema',
          TYPE_ICON.ERROR,
          DURATION_TOAST.MEDIUM
        );
        return false;
      }
      if (!response.estatus) {
        showToast(
          'Error de comunicación',
          response.mensaje,
          TYPE_ICON.ERROR,
          DURATION_TOAST.MEDIUM
        );
        return false;
      }
      const data = response.datos;
      const { padre_tutor: user, institucion: institution } = data;
      const userHasBeenLogged = await userExists(user.email);
      if (!userHasBeenLogged) {
        const userController = new UserController(user.email, pass);
        await userController.addUser(user);
      }

      const studentController = new StudentController(
        user.email,
        user.keyDecrypt
      );
      await studentController.bulkPutStudents(
        user.alumnos.map((student) => ({ ...student, idTutor: user.id }))
      );

      const institutionController = new InstitutionController(
        user.email,
        user.keyDecrypt
      );
      await institutionController.putInstitution(institution);

      const menu = await menuBoardService();
      const menuController = new MenuController(user.email, user.keyDecrypt);
      await menuController.updateMenu(menu);

      return data;
    } catch (e) {
      showToast(
        'Error desconocido',
        'Ha ocurrido un error desconocido. Intente de nuevo más tarde o contacte al administrador del sistema',
        TYPE_ICON.ERROR,
        DURATION_TOAST.MEDIUM
      );
      return false;
    }
  }

  async #offlineAuth(userName, pass) {
    try {
      const userHasBeenLogged = await userExists(userName);
      if (!userHasBeenLogged) {
        showToast(
          'Inicio de sesión online',
          'Es necesario un primer inicio de sesión a través de una conexión a internet antes de trabajar offline',
          TYPE_ICON.WARNING,
          DURATION_TOAST.LONG
        );
        return false;
      }

      const userController = new UserController(userName, pass);
      const user = await userController.readUser();
      const institutionController = new InstitutionController(
        userName,
        user.keyDecrypt
      );
      const institucion = await institutionController.readInstitution();
      return {
        padre_tutor: user,
        institucion: institucion[0],
      };
    } catch (e) {
      showToast(
        'Error desconocido',
        'Ha ocurrido un error desconocido. Intente de nuevo más tarde o contacte al administrador del sistema',
        TYPE_ICON.ERROR,
        DURATION_TOAST.MEDIUM
      );
      return false;
    }
  }
}

export default Auth;
