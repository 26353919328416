import BaseModel from './base.model';

class Institution extends BaseModel {
  constructor(email, secretKey) {
    super(email, secretKey, true);
  }

  addInstitution(institution) {
    return this.db.institution.add(institution);
  }

  putInstitution(institution) {
    return this.db.institution.put(institution);
  }

  readInstitution(id) {
    return this.db.institution.where({ id }).first();
  }

  putSchoolarships(schoolarships) {
    return this.db.transaction('rw', [this.db.schoolarships], async () => {
      await this.db.schoolarships.bulkPut(schoolarships);
    });
  }

  readSchoolarships(id) {
    return this.db.schoolarships.where({ idInstitucion: id }).toArray();
  }

  updateSchoolarShipFile(data) {
    return this.db.schoolarships_files.put(data);
  }

  async readSchoolarshipFile(id, idDocument) {
    const register = await this.db.schoolarships_files
      .where({ idInstitucion: id, idDocumento: idDocument })
      .first();
    return register?.file;
  }

  async readConfig(id) {
    const data = await this.readInstitution(id);
    return data?.config;
  }

  updateConfig(id, config) {
    return this.db.institution.where({ id }).modify({ config });
  }
}

export default Institution;
