import React from 'react';
import { ReactComponent as NormatecaIcon } from './../assets/imgs/normateca.svg';
import './../styles/components/Normateca.scss';

export default function Normateca() {
  return (
    <div className="normateca scale-hover">
      <a className="normateca-container cursor-pointer d-flex" href={'/'}>
        <div className="normateca-icon-container">
          <NormatecaIcon />
        </div>
        <div className="normateca-title d-flex">
          <span className="font-bold text-orange arvo-font my-auto">
            Normateca
          </span>
        </div>
      </a>
    </div>
  );
}
